import Image from "next/image";
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cssText from "./PromoText.module.scss";

SwiperCore.use([Autoplay]);

export default function PromoSwiperMobile({data, updateActive, updateSwiper}) {
  return (
    <Swiper
      className='promo-slider__glass'
      loop={true}
      spaceBetween={0}
      slidesPerView={1}
      watchSlidesVisibility={true}
      longSwipes={false}
      watchOverflow={true}
      onSwiper={(swiper) => updateSwiper(swiper)}
      autoplay={{delay: 5000, disableOnInteraction: false}}
      speed={1000}
      onActiveIndexChange={(swiper) => updateActive(swiper.activeIndex)}
    >
      {data.map((item, index) => (
        <SwiperSlide className='promo-slider__glass-slide' key={item.id}>
          {({isActive}) => (
            <>
              <div className='promo-slider__pic'>
                <Image
                  className={isActive ? 'promo-slider__pic-img promo-slider__pic-img--active' : 'promo-slider__pic-img'}
                  src={item.image_mobile_webp || item.image_mobile}
                  alt=""
                  width={'1920'}
                  height={'937'}
                  loading="lazy"
                />
              </div>
              <div className={cssText.wrap}>
                <div className={isActive ? `${cssText.text} ${cssText.active}` : cssText.text}>{item.text}</div>
              </div>
            </>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
