import Link from "next/link"
import PromoSlider from "./PromoSlider";

const Promo = ({data, isIE}) => {

    return (
        <section className='promo'>
            <div className='promo__title'>
                <h2>MYPRIORITY БАСМАННЫЙ</h2>
                <span>ЭТО</span>
            </div>
            <PromoSlider data={data} isIE={isIE}/>
            <Link href={"https://domrfbank.ru/"}>
                <a className='promo__fin' target="_blank" rel="noreferrer">
                    <img src={"/files/images/main/constr-fin-logo.png"} alt="Logo fin"/>
                </a>
            </Link>
        </section>
    )
}

export default Promo