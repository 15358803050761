import Slider from "react-slick"
import cssText from "./PromoText.module.scss";

export default function PromoSlick ({data, updateActive}) {

    const settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        afterChange: (e) => updateActive(++e),
    };


    return (
        <Slider {...settings}>
            {
                data.map(item => (
                    <div className='promo-slider__item' key={item.id}>
                        <div className='promo-slider__pic'>
                            <img src={item.image_big} alt="" loading={'lazy'} width={'1920'} height={'937'}/>
                        </div>
                        <div className={cssText.wrap}>
                            <div className={`${cssText.text} ${cssText['text--ie']}`}>{item.text}</div>
                        </div>
                    </div>
                ))
            }
        </Slider>
    )
}
