import css from './PromoScrollbar.module.scss'
import {useMemo} from "react";

export default function PromoScrollbar({index, size}) {

    const rotate = useMemo(() => {
        const deg = 175 / (size - 1) * --index
        return {transform: `rotate(${deg}deg)`}
    },[index])

    return (
        <div className={css.scrollbar}>
            <div className={css.drag} style={rotate}>
                <div className={css.line}></div>
            </div>
        </div>
    )
}