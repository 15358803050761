import Image from "next/image";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {EffectFade, Controller} from 'swiper';
import cssText from './PromoText.module.scss'

SwiperCore.use([EffectFade, Controller]);

export default function PromoSwiperClone ({data, updateSwiper, otherSwiper}) {
    return (
            <Swiper
                controller={{ control: otherSwiper }}
                className='promo-slider__glass'
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                watchSlidesVisibility={true}
                effect="fade"
                longSwipes={false}
                watchOverflow={true}
                onSwiper={(swiper) => updateSwiper(swiper)}
            >
                {data.map((item, index) => (
                    <SwiperSlide className='promo-slider__glass-slide' key={item.id}>
                        {({isActive}) => (
                            <>
                                <div className='promo-slider__pic'>
                                    <picture>
                                        <source srcSet={item.image_big_webp} type="image/webp"/>
                                        <img className={isActive ? 'promo-slider__pic-img promo-slider__pic-img--active' : 'promo-slider__pic-img'} src={item.image_big_webp + '.jpg'} alt="" loading={'lazy'} width={'1920'} height={'937'}/>
                                    </picture>
                                </div>
                                <div className={cssText.wrap}>
                                    <div className={isActive ? `${cssText.text} ${cssText.active}` : cssText.text}>{item.text}</div>
                                </div>
                            </>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
    )
}
