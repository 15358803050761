import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, { Keyboard, Autoplay, Controller} from 'swiper';
import Image from 'next/image'

SwiperCore.use([Keyboard, Autoplay, Controller]);

export default function PromoSwiper ({data, updateSwiper, otherSwiper, updateActive}) {

    return (
            <Swiper
                controller={{ control: otherSwiper }}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                watchSlidesVisibility={true}
                className='promo-slider'
                onSwiper={(swiper) => updateSwiper(swiper)}
                autoplay={{delay: 5000, disableOnInteraction: false}}
                speed={1000}
                keyboard={{enabled: true, onlyInViewport: true}}
                longSwipes={false}
                watchOverflow={true}
                onActiveIndexChange={(swiper) => updateActive(swiper.activeIndex)}
            >
                {
                    data.map((item, index) => (
                        <SwiperSlide className='promo-slider__item' key={item.id}>
                            <div className='promo-slider__pic'>
                                <picture>
                                    <source srcSet={item.image_mobile} media="(max-width: 480px)" type="image/webp"/>
                                    <source srcSet={item.image_mobile_webp} media="(max-width: 480px)" type="image/jpeg"/>
                                    <source srcSet={item.image_big_webp} type="image/webp"/>
                                    <img src={item.image_big} alt="" loading={'lazy'} width={'100%'} height={'937'}/>
                                </picture>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
    )
}