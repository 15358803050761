import css from './PromoNav.module.scss'

export default function PromoNav({active, data, updateActive}) {

    return (
        <div className={css.nav}>
            {data.map(({id}) => (
                <div
                    key={id}
                    className={`${css.item} ${id === active ? css.active : ''}`}
                    onClick={() => updateActive(id)}
                >
                </div>

            ))}
        </div>
    )
}